import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import Img from '../img/404.svg'

const NotFoundPage = () => (
  <Layout>
    <div className="container">
    <div className="page-not-found">
        <img src={Img} alt="NOT FOUND" />
      <h1>PAGE NOT FOUND</h1>
      <p>The page you are looking for is temporarily unavailable.</p>
      <p>
        If you keep coming back here please{" "}
        <Link to="/contact">contact us</Link>.
      </p>
    </div>
    </div>
  </Layout>
);

export default NotFoundPage
